import React, { useState, useEffect } from 'react';
import { ServicesMenu } from './ServicesMenu/ServicesMenu';
import { Stack } from 'react-bootstrap';
import IndividualTest from './IndividualTest/IndividualTest';
import Scanning from './ScanningTest/Scanning';
import Packages from './Packages/Packages';

const ServiceChanges = () => {
    const [service, setService] = useState('individual');

    useEffect(() => {
        const savedService = localStorage.getItem('selectedService');
        if (savedService) {
            setService(savedService);
        }
    }, []);

    const handleServiceChange = (selectedService) => {
        setService(selectedService);
        localStorage.setItem('selectedService', selectedService); 
    };

    return (
        <Stack>
            <ServicesMenu onHandleServiceChange={handleServiceChange} service={service} />
            {service === 'individual' && <IndividualTest />}
            {service === 'scanning' && <Scanning />}
            {service === 'packages' && <Packages />}
        </Stack>
    );
};

export { ServiceChanges };
