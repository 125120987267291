import { LOGIN_ACTIONS } from './Polo.actions';

export const LoginReducer = (state, action) => {
    switch (action.type) {
        case LOGIN_ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.data
            };
        case LOGIN_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.data
            };
        case LOGIN_ACTIONS.SET_USER:
            return {
                ...state,
                user: action.data
            };
        default:
            return { ...state };
    }
};
