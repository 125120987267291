import { Theme } from "../../Theme"

export const ActiveBookingGridStyles = () => {
    return {
        table: {
            width: '100%',
            margin: "0px 0px",
            borderCollapse: 'collapse',
            textAlign: 'center',
            padding: '10px',
            position: 'relative',
            maxHeight: '400px',
            overflowY: 'auto',
        },
        header: {
            backgroundColor: ' #000000',
            color: Theme.color.white,
            height: '53px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
        cell: {
            borderLeft: '1px solid #333',
            borderRight: '1px solid #333',
            borderBottom: "5px solid #fff",
            '&:first-child': {
                borderLeft: 'none'
            },
            '&:last-child': {
                borderRight: 'none'
            },
            padding: '10px',
            marginBottom: '5px solid white'
        },
        headerCell: {
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
            padding: '8px',
            '&:first-child': {
                borderLeft: 'none'
            },
            '&:last-child': {
                borderRight: 'none'
            }
        },
        row: {
            backgroundColor: '#FFDADA',
            '&:not(:last-child)': {

            }
        },
        add: {
            background: '#E64431',
            color: Theme.color.white,
            margin: '10px'
        },
        tableContainer: {
            width: '100%',
            margin: "0px 30px",

        },
        filter:{
            display:"flex",
            flexDirection:"row",
        },
        filterCell: {
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
            padding: '10px',
            display:"flex",
            flexDirection:"row",
            justifyContent:"center",
            alignItems:"center",
            textAlign:"center",
            '&:first-child': {
                borderLeft: 'none'
            },
            '&:last-child': {
                borderRight: 'none'
            }
        },
        filterToggle:{
            background:"none",
        },
        item:{
            color:Theme.color.primary,
        },
    };
};