import { Theme } from "../../../Theme";

export const MenuItemStyles = () => {
    return {
        cardContainer: {
            backgroundColor: '#1131A2',
            borderRadius: '15px',
            height: "160px",
            width: "320px",
            flex: 'unset',
            cursor: 'pointer',
            alignItems: "center",

        },
        headText: {
            ...Theme.typography.textFK2DW800S30,
            color: Theme.color.white,
            padding: "20px",
            textAlign: "center",
            width: '100%'
        },
        colorImage: {
            verticalAlign: "middle",
            height: "160px",
            width: "60%",
        },

    }
}