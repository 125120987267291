import React from 'react';
import { Container, Alert } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { HistoryStyles } from './History.styles';
import { ActiveBookingGrid } from '../../../Components/ActiveBookingGrid/ActiveBookingGrid';
import { useInit } from './History.hook';
import { DNA } from 'react-loader-spinner';

const useStyles = createUseStyles(HistoryStyles);

const History = () => {
    const classes = useStyles();
    const { state, actions } = useInit();
    React.useEffect(() => {
        actions.getHistoryAction();
    }, []);

    React.useEffect(() => {
        console.log('State after fetching history:', state);
    }, [state]);

    return (
        <Container fluid className={classes.container}>
            {state.error && <Alert key="danger" variant="danger">{state.error}</Alert>}
            {state.isLoading ? (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <DNA
                        visible={state.isLoading}
                        height="130" width="130"

                        ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
                </div>
            ) : (
                <ActiveBookingGrid
                    showMoveButton={false}
                    showDropdown={false}
                    showSearchInput={true}
                    data={state.history?.bookingHistory?.length > 0 ? state.history.bookingHistory : []}
                />
            )}
        </Container>
    );
};

export default History;
