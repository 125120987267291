import { Theme } from "../../../../Theme"


export const PackagesStyles = () => {
    return {
        container: {
            background: Theme.color.transparent
        },
        middileCard: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px",

        },
        input: {
            background: " #D9D9D9",
            ...Theme.typography.textFHeeboW500S18,
            color: "black",
            width: "140%",
            padding: "10px",

        },
        selectTest: {
            background: " #D9D9D9",
            ...Theme.typography.textFHeeboW500S18,
            color: "black",
            width: "150%",
            padding: "12px",


        },
        item: {
            display: "flex",
            flexDirection: "row",
            gap: "15px",

        },
        menu: {
            width: "150%",
        },
        checkMark: {
            color: "green",
            fontWeight: "bold",
            cursor: "pointer",
        },
        scrollableMenu: {
            maxHeight: "200px",
            overflowY: "auto",
        },
        add: {
            background: "#E64431",
            textAlign: "center",
            ...Theme.typography.textFHeeboW500S18,
            padding: "15px",
            borderRadius: "20px",
            border: "1px solid white",
            width: "50%",
        },
        packagesPrice: {
            background: " #D9D9D9",
            ...Theme.typography.textFHeeboW500S18,
            color: "black",
            width: "60%",
            padding: "10px",
        }
    }
}