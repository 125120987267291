
import { SCANNING_ACTIONS } from "./Scanning.actions";

export const ScanningReducer = (state, action) => {
    switch (action.type) {
        case SCANNING_ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.data
            };
        case SCANNING_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.data
            };

        case SCANNING_ACTIONS.SET_SCANNING:
            return {
                ...state,
                scanning: action.data
            };
        default:
            return { ...state };
    }
}