import React, { useRef, useEffect, useState } from 'react';
import { Container, Button, Col, Dropdown, Form, Row, Stack, Alert } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { ServicesGrid } from '../../../../Components/ServicesGrid/ServicesGrid';
import { useInit } from './HealthPackage.hook';
import { PackagesStyles } from './Packages.styles';
import { DNA } from 'react-loader-spinner';

const useStyles = createUseStyles(PackagesStyles);

const Packages = () => {
    const classes = useStyles();
    const { state, actions } = useInit();
    const [dropdownSearch, setDropdownSearch] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [newPackage, setPackage] = useState({
        name: '',
        price: '',
        testIds: []
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dropdownToggleRef = useRef(null);
    const dropdownMenuRef = useRef(null);

    console.log('STATE::', state);
    const handleTestSelect = (selectedId) => {
        console.log("IDS", selectedId, newPackage?.testIds);
        let filterData = []
        if (newPackage?.testIds.includes(selectedId)) {
            filterData = newPackage?.testIds.filter(id => id !== selectedId);
        } else {
            filterData = [...newPackage?.testIds, selectedId];
        }
        setPackage({ ...newPackage, testIds: filterData });
        validateSelection(filterData);
    };

    const handleDropdownToggle = (isOpen) => {
        if (!isOpen) {
            setDropdownOpen(true);
        }
    };

    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const validateSelection = (testIds) => {
        if (testIds.length < 2) {
            setErrorMessage("Please select at least two tests.");
            setIsFormValid(false);
        } else {
            setErrorMessage("");
            setIsFormValid(true);
        }
    };

    const addPackageToList = () => {
        console.log("newPackage ::", newPackage);
        if (newPackage.name.length > 0 && newPackage.price.length > 0 && !isNaN(Number(newPackage.price)) && newPackage.testIds.length >= 2) {
            actions.postPackage(newPackage.name, newPackage.price, newPackage.testIds);
            setPackage({
                name: '',
                price: '',
                testIds: []
            });
        } else {
            actions.setError("Please check input values");
        }
    }

    const handleDeleteAction = (item) => {
        actions.deletePackage(item.id)
    }

    const handleClickOutside = (event) => {
        if (
            dropdownToggleRef.current &&
            !dropdownToggleRef.current.contains(event.target) &&
            dropdownMenuRef.current &&
            !dropdownMenuRef.current.contains(event.target)
        ) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <Container fluid className={classes.container}>
            {state.error && <Alert key={"danger"} variant={"danger"}>
                {state.error}
            </Alert>}
            {errorMessage && <Alert key={"warning"} variant={"warning"} className={classes.errorMessage}>
                {errorMessage}
            </Alert>}
            <Stack className={classes.middileCard}>
                <Row className="w-100 justify-content-center ms-5" style={{ gap: "50px" }} >
                    <Col xs={12} md={3} className="mb-3">
                        <Form.Control style={{ margin: "0rem -5rem" }}
                            type='text'
                            placeholder={'Enter Package Name'}
                            className={classes.input}
                            value={newPackage.name}
                            onChange={(event) => { setPackage({ ...newPackage, name: event.target.value }) }}
                        />
                    </Col>
                    <Col xs={12} md={3} className="mb-3">
                        <Form.Control style={{ width: "100%", margin: "0px -30px", marginLeft:"30px" }}
                            type='number'
                            placeholder={'Enter price'}
                            value={newPackage.price}
                            className={classes.input}
                            onChange={(event) => { setPackage({ ...newPackage, price: event.target.value }) }}
                        />
                    </Col>
                    <Col xs={12} md={3} className="mb-3">
                        <Dropdown show={dropdownOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle
                                ref={dropdownToggleRef}
                                className={classes.selectTest}
                                onClick={handleDropdownClick}
                            >
                                {newPackage?.testIds.length > 0 ? `${newPackage?.testIds.length} selected` : 'Select Test'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownMenuRef} className={classes.menu}>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={dropdownSearch}
                                    onChange={(e) => setDropdownSearch(e.target.value)}
                                    className={classes.searchBar}
                                />
                                <div className={classes.scrollableMenu}>
                                    {state.allTests?.tests
                                        ?.filter((test) =>
                                            test.name.toLowerCase().includes(dropdownSearch.toLowerCase())
                                        )
                                        .map((test) => (
                                            <Dropdown.Item
                                                key={test.id}
                                                className={classes.item}
                                                onClick={() => handleTestSelect(test.id)}
                                            >
                                                <Form.Check
                                                    aria-label={`option ${test.id}`}
                                                    checked={newPackage?.testIds.includes(test.id)}
                                                    className={classes.checkMark}
                                                    onChange={() => handleTestSelect(test.id)}
                                                />
                                                {test.name}
                                            </Dropdown.Item>
                                        ))}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Col xs={12} md={3} className="mb-3 d-flex justify-content-center">
                    <Button className={classes.add} onClick={addPackageToList} disabled={!isFormValid}>
                        Add
                    </Button>
                </Col>
            </Stack>
            <div
                onClick={(e) => {
                    if (e.target !== dropdownToggleRef.current && !dropdownToggleRef.current.contains(e.target)) {
                        setDropdownOpen(false);
                    }
                }}
            >
                <ServicesGrid
                    headerName={'Package Name'}
                    tests="Tests"
                    showTests={true}
                    data={state.healthPackage?.packages?.length > 0 ? state.healthPackage?.packages : []}
                    deleteAction={handleDeleteAction}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <DNA
                    visible={state.isLoading}
                    height="130" width="130"
                    ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
            </div>
        </Container>
    );
};

export default Packages;
