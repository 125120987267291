import { HttpClient } from "../../../../Common/HttpClient/HttpClient";
export const getScanningList = async () => {
    try {
        const response = await HttpClient.get('/test/scan');
        if (response.status === 200) {
            return {
                statusCode: 200,
                scanning: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Somthing want wrong please try again'
            };
        }
    } catch (error) {
        console.log('Error', error);
        return {
            statusCode: 404,
            error: error.message
        };
    }
};
export const createTest = async (name, price, type, id) => {
    try {
        const params = {
            name,
            price,
            type
        };
        let response;
        if (id) {
            response = await HttpClient.put(`/test/${id}`, params);
        } else {
            response = await HttpClient.post('/test', params);
        }
        if (response.status === 200 || response.status === 201) {
            return {
                statusCode: response.status,
                scanning: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Something went wrong, please try again'
            };
        }
    } catch (error) {
        return {
            statusCode: error.response.status || 404,
            error: error.message
        };
    }
};
export const deleteTest = async (id) => {
    try {
        const response = await HttpClient.delete(`/test/${id}`);
        if (response.status === 200) {
            return {
                statusCode: 200,
                message: 'Test deleted successfully'
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Something went wrong, please try again'
            };
        }
    } catch (error) {
        return {
            statusCode: error.response ? error.response.status : 404,
            error: error.message
        };
    }
};
