
export const HistoryStyles = () => {
    return {
        container: {
            width: "90%",
            marginLeft: "-0.2rem",
            marginTop: "30px"
        },
        loader:{
            diplay:"flex",
            flexDirection:"row",
            justifyContent:"right",
            marginLeft:"0px"
            
        }
    }
}