import { Theme } from "../../../../Theme";

export const ServicesMenuStyles = () => {
    return {
        topCard: {
            padding: "16px;",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
        },
        button: {
            background: "none",
            textAlign: "center",
            border: "2px solid #1131A2",
            ...Theme.typography.textFHeeboW500S18,
            color: Theme.color.optional,
            borderRadius: "20px",
            padding: "20px",
            width: "15rem",
            margin: "20px "
        },
    }
}