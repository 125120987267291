import axios from 'axios';
const HttpClient = axios.create({
	baseURL: 'https://api.poloscananddiagnostics.com/v1/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

const setTokentoHeader = (token) => {
    HttpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export { HttpClient, setTokentoHeader };

