import { HttpClient } from "../../../Common/HttpClient/HttpClient";

export const getBookingsList = async () => {
    try {
        const response = await HttpClient.get('/booking/active');
        if (response.status === 200) {
            return {
                statusCode: 200,
                bookings: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Somthing want wrong please try again'
            };
        }
    } catch (error) {
        console.log('Error', error);
        return {
            statusCode: 404,
            error: error.message
        };
    }
};
export const updateBooking = async (bookingId, updateData) => {
    try {
        const response = await HttpClient.patch(`/booking/${bookingId}`, updateData);
        if (response.status === 200) {
            return {
                statusCode: 200,
                updateBooking: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Something went wrong, please try again'
            };
        }
    } catch (error) {
        console.log('Error', error);
        return {
            statusCode: 404,
            error: error.message
        };
    }
};
