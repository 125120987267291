import React, { useState } from 'react';
import { Button, Col, Container, Dropdown, Form, Image, Row, Stack } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import dlt from '../../Resources/Assets/delete.png';
import edit from '../../Resources/Assets/edit.png';
import search from '../../Resources/Assets/search.png';
import { ServicesGridStyles } from './ServicesGrid.styles';

const useStyles = createUseStyles(ServicesGridStyles);

const ServicesGrid = ({ headerName, showTests, tests, isEdit, deleteAction, data, saveAction }) => {
    const classes = useStyles();

    const [editValues, setEditValues] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearch, setShowSearch] = useState(false);

    const handleSaveClick = () => {
        saveAction({ ...editValues });
        setEditValues(null);
    };

    const handleEditClick = (item) => {
        setEditValues({ ...item });
    };

    const handleDeleteClick = (item) => {
        deleteAction(item);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = data?.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (

        <Stack style={{ maxHeight: '400px', overflow: 'auto', margin: "0px 80px" }}>
            <table className={classes.table}>
                <thead className={classes.tableHead}>
                    <tr className={classes.header}>
                        <th className={classes.headerCell}>S.No</th>
                        <th className={classes.headerCell}>
                            {headerName}
                            <Image
                                src={search}
                                className={classes.search}
                                alt="search"
                                onClick={() => setShowSearch(!showSearch)}
                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                            />
                            {showSearch && (
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className={classes.searchInput}
                                    style={{ marginLeft: '10px' }}
                                />
                            )}
                        </th>
                        {showTests && <th className={classes.headerCell}>{tests}</th>}
                        <th className={classes.headerCell}>Price</th>
                        <th className={classes.headerCell}>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData?.map((item, index) => (
                        <tr key={item.id} className={classes.row}>
                            <td className={classes.cell}>{index + 1}</td>
                            <td className={classes.cell}>
                                {editValues?.id === item.id ? (
                                    <input type="text" name="name" value={editValues.name} onChange={handleChange} />
                                ) : (
                                    item.name
                                )}
                            </td>
                            {showTests && (
                                <td className={classes.cell}>
                                    {editValues?.id === item.id ? (
                                        <input type="text" name="tests" value={editValues.tests} onChange={handleChange} />
                                    ) : (
                                        <ol className={classes.cellList}>
                                            {console.log("TESTS ::", item.tests)}

                                            {item.tests.map(testItem => {
                                                return <li>{testItem.name}</li>
                                            })}


                                        </ol>

                                    )}
                                </td>
                            )}
                            <td className={classes.cell}>
                                {editValues?.id === item.id ? (
                                    <input type="text" name="price" value={editValues.price} onChange={handleChange} />
                                ) : (
                                    item.price
                                )}
                            </td>
                            <td className={classes.cell}>
                                {editValues?.id === item.id ? (
                                    <button className={classes.save} onClick={() => handleSaveClick()}>
                                        Save
                                    </button>
                                ) : (
                                    <>
                                        {isEdit && <Image src={edit} alt="edit" className={classes.vector} onClick={() => handleEditClick(item)} />}
                                        <Image src={dlt} alt="delete" onClick={() => handleDeleteClick(item)} />
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Stack>

    );
};

export { ServicesGrid };
