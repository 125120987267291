import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { IndividualTestStyles } from './IndividualTest.styles';
import { createUseStyles } from 'react-jss';
import { useInit } from './Tests.hook';
import { ServicesGrid } from '../../../../Components/ServicesGrid/ServicesGrid';
import { Button, Col, Alert, Form, Row, Stack } from 'react-bootstrap';
import { DNA } from 'react-loader-spinner';


const useStyles = createUseStyles(IndividualTestStyles);

const IndividualTest = () => {
    const classes = useStyles();
    const [test, setTest] = useState({
        name: '',
        price: ''
    });
    const { state, actions } = useInit();


    const addTestToList = () => {
        if (test.name.length > 0 && test.price.length > 0 && !isNaN(Number(test.price))) {
            actions.postAction(test.name, Number(test.price), 'lab');
            setTest({
                name: '',
                price: ''
            });
        } else {
            actions.setError("Please check input values")
        }
    }
    const handleDeleteAction = (item) => {
        actions.deleteAction(item.id)
    }

    const handleSaveAction = (item) => {
        actions.saveAction(item.name, Number(item.price), item.type, item.id);
    }

    return (
        <Container fluid className={classes.container}>
            {state.error && <Alert key={"danger"} variant={"danger"}>
                {state.error}
            </Alert>}
            <Stack className={classes.middileCard}>
                <Row className="w-100 justify-content-left ms-5 " style={{ gap: "220px" }} >
                    <Col xs={12} md={3} className="mb-3">
                        <Form.Control
                            type='text'
                            placeholder={'Enter Test'}
                            className={classes.input}
                            value={test.name}
                            onChange={(event) => { setTest({ ...test, name: event.target.value }) }}
                        />
                    </Col>
                    <Col xs={12} md={3} className="mb-3">
                        <Form.Control style={{ width: "100%" }}
                            type='number'
                            placeholder={'Enter  price'}
                            value={test.price}
                            className={classes.input}
                            onChange={(event) => { setTest({ ...test, price: event.target.value }) }}
                        />
                    </Col>
                </Row>
                <Col xs={12} md={3} className="mb-3 d-flex justify-content-center">
                    <Button className={classes.add} onClick={() => { addTestToList() }}>
                        Add
                    </Button>
                </Col>
            </Stack>
            <ServicesGrid
                headerName={'Test Name'}
                showTests={false}
                isEdit={true}
                data={state.tests?.tests ?? []}
                deleteAction={(item) => {
                    handleDeleteAction(item)
                }}
                saveAction={(item) => {
                    handleSaveAction(item)
                }}
            />
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <DNA
                    visible={state.isLoading}
                    height="130" width="130"

                    ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
            </div>
        </Container>
    );
};

export default IndividualTest;
