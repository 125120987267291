import { Theme } from "../../Theme";

export const ServicesGridStyles = () => {
  return {

    table: {
      borderCollapse: 'collapse',
      textAlign: "center",
      padding: "10px",
      position: 'relative',
      maxHeight: '400px',
      overflowY: 'auto',
      Width: "100%",

    },
    header: {
      backgroundColor: Theme.color.optional,
      color: Theme.color.white,
      height: "53px",

    },
    tableHead: {
      position: 'sticky',
      top: 0,
      zIndex: 1,

    },
    cell: {
      borderLeft: '1px solid #333',
      borderRight: '1px solid #333',
      borderBottom: '5px solid #fff',
      padding: '8px',
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
      },
      borderBottom: "5px solid white",
    },
    headerCell: {
      borderLeft: '1px solid white',
      borderRight: '1px solid white',
      padding: '8px',
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
    cellList: {
      textAlign: "left",
    },
    row: {
      backgroundColor: '#FFDADA',
      '&:not(:last-child)': {
      },
    },
    vector: {
      margin: "auto",
      padding: "15px"
    },
    search: {
      margin: "10px",
      marginLeft: "10px"
    },
    save: {
      background: "none",
      borderRadius: "12px",
      border: "2px solid black",
      padding: "10px",
      width: "100%",
    },


  }
}