import { Theme } from "../../Theme"

export const PoloStyles = () => {
    return {
        body: {
            background: Theme.color.white,
            padding: "40px",
        },
        head: {
            ...Theme.typography.textFK2DW800S60,
            color: "#1131A2",
            padding: "20px",
        },
        topText: {
            dispaly: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        admin: {
            ...Theme.typography.textFK2DW800S30,
            color: Theme.color.optional,


        },
        leftPan: {
            margin: "0px",
            marginLeft: "100px",
        },
        input: {
            width: "80%",
            border: "1px solid #000000",
            background: "#D9D9D9",
            padding: "15px",
        },
        lable: {
            ...Theme.typography.textFHeeboW500S18,
            lineHeight: "24px",
        },
        img: {
            width: "100%",
            margin: "0 -5rem",
        },
        error: {
            color: 'red',
            fontSize: '0.875em',
            marginTop: '0.25em',
            fontWeight: 'bold',
        },
        submit: {
            ...Theme.typography.textFHeeboW500S20,
            background: "#00CC45",
            width: "70%",
            padding: "10px",
            marginLeft: " 2rem",
            marginTop: "55px"
        },
    }
}



