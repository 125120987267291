import { HttpClient } from '../../Common/HttpClient/HttpClient';

export const getLogin = async (userId, password) => {
    try {
        const params = {
            userId,
            password
        };
        const response = await HttpClient.post('/user/login', params);
        if (response.status === 200) {
            return {
                statusCode: 200,
                user: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Somthing went wrong please try again'
            };
        }
    } catch (error) {
        return {
            statusCode: 404,
            error: error.message
        };
    }
};
