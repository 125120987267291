export const ActiveBookingStyles = () => {
    return {
        container: {
            width: "90%",
            marginLeft: "-0.1rem",
            marginTop: "30px"
        },

    };

}