import { useReducer } from 'react';
import { BookingsReducer } from './ActiveBookings.reducer';
import { BookingsInitialState } from './ActiveBookings.models';
import { BOOKINGS_ACTIONS } from './ActiveBookings.actions';
import { getBookingsList, updateBooking } from './ActiveBookings.services';

export const useInit = () => {
    const [state, dispatch] = useReducer(BookingsReducer, BookingsInitialState);
    const actions = BookingsActions(dispatch);

    return { state, actions };
};

const BookingsActions = (dispatch) => {
    const getBookingsAction = async () => {
        dispatch({ type: BOOKINGS_ACTIONS.SET_LOADING, data: true });
        try {
            const response = await getBookingsList();
            if (response.statusCode === 200) {
                dispatch({ type: BOOKINGS_ACTIONS.SET_BOOKINGS, data: response.bookings });
            } else {
                dispatch({ type: BOOKINGS_ACTIONS.SET_ERROR, data: response.error });
            }
        } catch (error) {
            console.error("Error:", error);
            dispatch({ type: BOOKINGS_ACTIONS.SET_ERROR, data: error.message });
        } finally {
            dispatch({ type: BOOKINGS_ACTIONS.SET_LOADING, data: false });
        }
    };

    const updateBookingAction = async (bookingId, updateData) => {
        dispatch({ type: BOOKINGS_ACTIONS.SET_LOADING, data: true });
        try {
            const response = await updateBooking(bookingId, updateData);
            if (response.statusCode === 200) {
                dispatch({ type: BOOKINGS_ACTIONS.SET_UPDATEBOOKING_STATUS, data: response.updateBooking });
            } else {
                dispatch({ type: BOOKINGS_ACTIONS.SET_ERROR, data: response.error });
            }
        } catch (error) {
            console.error("Error:", error);
            dispatch({ type: BOOKINGS_ACTIONS.SET_ERROR, data: error.message });
        } finally {
            dispatch({ type: BOOKINGS_ACTIONS.SET_LOADING, data: false });
        }
    };

    return {
        getBookingsAction,
        updateBookingAction
    };
};
