import { useReducer } from 'react';
import { LoginReducer } from './Polo.reducer';
import { loginInitialState } from './Polo.models';
import { getLogin } from './Polo.services';
import { LOGIN_ACTIONS } from './Polo.actions';

export const useInit = () => {
    const [state, dispatch] = useReducer(LoginReducer, loginInitialState);
    const actions = LoginActions(dispatch);
    return { state, actions };
};
export const LoginActions = (dispatch) => {
    const actions = {
        getLoginAction: async (userId, password) => {
            dispatch({
                type: LOGIN_ACTIONS.SET_LOADING,
                data: true
            });
            const response = await getLogin(userId, password);
            if (response.statusCode === 200) {
                dispatch({
                    type: LOGIN_ACTIONS.SET_USER,
                    data: response.user
                });
            } else {
                dispatch({
                    type: LOGIN_ACTIONS.SET_ERROR,
                    data: response.user
                });
            }
            dispatch({
                type: LOGIN_ACTIONS.SET_LOADING,
                data: false
            });
        }
    };
    return actions;
};
