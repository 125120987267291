import React from "react";
import { Stack } from "react-bootstrap";
import { createUseStyles } from "react-jss";
import { MenuItemStyles } from "./MenuItem.styles";


const useStyles = createUseStyles(MenuItemStyles);

const MenuItem = ({ title, image, onHandleClick }) => {
  const classes = useStyles();

  return (
    <Stack className={classes.cardContainer} direction='horizontal' onClick={() => onHandleClick()}>
      <h1 className={classes.headText}>{title}</h1>
      {image && <img src={image} width={100} className={classes.colorImage} />}
    </Stack>
  );
};

export { MenuItem };
