import React from 'react'
import { Container } from 'react-bootstrap';
import { createUseStyles } from "react-jss";
import { ScanningStyles } from './Scanning.style';
import { ServicesGrid } from '../../../../Components/ServicesGrid/ServicesGrid';
import { useInit } from './Scanning.hook';
import { DNA } from 'react-loader-spinner';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';


const useStyles = createUseStyles(ScanningStyles);
const Scanning = () => {
    const classes = useStyles();
    const { state, actions } = useInit();
    const [scan, setScan] = React.useState({
        name: '',
        price: ''

    });
    console.log("STATE ::", state)
    const addScanToList = () => {
        if (scan.name.length > 0 && scan.price.length > 0 && !isNaN(Number(scan.price))) {
            actions.postAction(scan.name, Number(scan.price), 'scan');
            setScan({
                name: '',
                price: ''
            });
        } else {
            actions.setError("Please check input values")
        }
    }

    const handleDeleteAction = (item) => {
        actions.deleteAction(item.id)
    }

    const handleSaveAction = (item) => {
        actions.saveAction(item.name, Number(item.price), item.type, item.id);
    }

    return (
        <Container fluid className={classes.container}>
            <Stack className={classes.middileCard}>
                <Row className="w-100 justify-content-left ms-5" style={{ gap: "220px" }}>
                    <Col xs={12} md={3} className="mb-3">
                        <Form.Control
                            type='text'
                            placeholder={'Enter Scanning'}
                            className={classes.input}
                            value={scan.name}
                            onChange={(event) => { setScan({ ...scan, name: event.target.value }) }}
                        />
                    </Col>
                    <Col xs={12} md={3} className="mb-3">
                        <Form.Control style={{ width: "100%" }}
                            type='number'
                            placeholder={'Enter  price'}
                            value={scan.price}
                            className={classes.input}
                            onChange={(event) => { setScan({ ...scan, price: event.target.value }) }}
                        />
                    </Col>

                </Row>
                <Col xs={12} md={3} className="mb-3 d-flex justify-content-center">
                    <Button className={classes.add} onClick={() => { addScanToList() }}>
                        Add
                    </Button>
                </Col>
            </Stack>
            <ServicesGrid
                headerName={'Scanning Name'}
                showTests={false}
                isEdit={true}
                data={state.scanning?.scans?.length > 0 ? state.scanning?.scans : []}
                deleteAction={(item) => {
                    handleDeleteAction(item)
                }}
                saveAction={(item) => {
                    handleSaveAction(item)
                }}
            />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <DNA
                    visible={state.isLoading}
                    height="130" width="130"

                    ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
            </div>      
            </Container>
    );
}

export default Scanning
