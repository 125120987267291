import React, { useState, useEffect } from 'react';
import { MenuItem } from './MenuItem/MenuItem';
import { Stack } from 'react-bootstrap';
import ActiveBookingsBg from "../../Resources/Assets/greenBg.png";
import HistoryBg from "../../Resources/Assets/redBg.png";
import ServicesBg from "../../Resources/Assets/yellowBg.png";
import { useNavigate } from 'react-router-dom';

const Menu = () => {
    const [activeCardIndex, setActiveCardIndex] = useState(0);
    const navigate = useNavigate();
    
    useEffect(() => {
        const savedIndex = localStorage.getItem('activeCardIndex');
        if (savedIndex !== null) {
            setActiveCardIndex(Number(savedIndex));
        }
    }, []);

    const handleClick = (cardIndex, path) => {
        setActiveCardIndex(cardIndex);
        localStorage.setItem('activeCardIndex', cardIndex);
        navigate(path);
    };

    return (
        <Stack direction='horizontal' gap={100} style={{
            justifyContent: 'space-around', textAlign: 'center'
        }}>
            <MenuItem title={'Active Bookings'} image={activeCardIndex === 0 ? ActiveBookingsBg : null} onHandleClick={() => { handleClick(0, '/bookings') }} />
            <MenuItem title={'History'} image={activeCardIndex === 1 ? HistoryBg : null} onHandleClick={() => { handleClick(1, '/history') }} />
            <MenuItem title={'Service Changes'} image={activeCardIndex === 2 ? ServicesBg : null} onHandleClick={() => { handleClick(2, '/services') }} />
        </Stack>
    );
};

export default Menu;
