import React, { useContext, useState, useEffect } from 'react';
import { Container, Stack, Image, Button, Col, Row, Form } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext/AuthContext';
import { Header } from '../../Components/Header/Header';
import polo from '../../Resources/Assets/poloscan.png';
import { SUBMIT } from './Polo.constants';
import { PoloStyles } from './Polo.styles';
import { useInit } from './Polo.hook';

const useStyles = createUseStyles(PoloStyles);


const Polo = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { state, actions } = useInit();

    useEffect(() => {
        if (state.user?.token?.length > 0) {
            login(state.user);
            navigate('/bookings');
        }
    }, [state.user, state.error, login, navigate]);

    const onHandleLogin = () => {
        let valid = true;
        if (!email) {
            setEmailError('Please enter a valid email address.');
            valid = false;
        } else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('Please enter a valid password.');
            valid = false;
        } else {
            setPasswordError('');
        }

        if (valid) {
            actions.getLoginAction(email, password);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onHandleLogin();
    };
    return (
        <Container fluid className={classes.container}>
            <Header />
            <Row className="align-items-center">
                <Col md={6}>
                    <Stack className={classes.leftPan}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                <Form.Label className={classes.label}>Enter User Id</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter email"
                                    value={email}
                                    className={classes.input}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                                {emailError && <div className={classes.error}>{emailError}</div>}

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                <Form.Label className={classes.label}>Enter Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    className={classes.input}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                {passwordError && <div className={classes.error}>{passwordError}</div>}
                            </Form.Group>
                            <Button
                                variant="primary"
                                className={classes.submit}
                                type="submit"
                            >
                                {SUBMIT}
                            </Button>
                        </Form>
                    </Stack>
                </Col>
                <Col md={6}>
                    <Image src={polo} alt="Image description" className={classes.img} />
                </Col>
            </Row>
        </Container>
    );
};

export { Polo };
