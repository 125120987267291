import { useReducer } from 'react';
import { HistoryReducer } from './History.reducer';
import { HistoryInitialState } from './History.models';
import { getHistoryList } from './History.services';
import { HISTORY_ACTIONS } from './History.actions';

export const useInit = () => {
    const [state, dispatch] = useReducer(HistoryReducer, HistoryInitialState);
    const actions = HistoryActions(dispatch);

    return { state, actions };
};

const HistoryActions = (dispatch) => {
    const actions = {
        getHistoryAction: async () => {
            dispatch({
                type: HISTORY_ACTIONS.SET_LOADING,
                data: true
            });
            const response = await getHistoryList();
            if (response.statusCode === 200) {
                dispatch({
                    type: HISTORY_ACTIONS.SET_HISTORY,
                    data: response.history
                });
            } else {
                dispatch({
                    type: HISTORY_ACTIONS.SET_ERROR,
                    data: response.error
                });
            }

            dispatch({
                type: HISTORY_ACTIONS.SET_LOADING,
                data: false
            });
        }
    };

    return actions;
};
