import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Stack, Button, Image, DropdownButton, Dropdown } from 'react-bootstrap';
import search from "../../Resources/Assets/search.png"
import { ActiveBookingGridStyles } from './ActiveBookingGrid.styles';
import { useInit } from '../../Containers/MainAdminPortal/ActiveBookings/ActiveBookings.hook';

const useStyles = createUseStyles(ActiveBookingGridStyles);

const ActiveBookingGrid = ({ showMoveButton, data, showDropdown, showSearchInput }) => {
    const classes = useStyles();
    const { actions } = useInit();
    const [bookings, setBookings] = useState(data);
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [sampleFilter, setSampleFilter] = useState('');

    useEffect(() => {
        setBookings(data);
    }, [data]);

    const handleMoveClick = (bookingId) => {
        const updateData = { status: 'Completed' };
        actions.updateBookingAction(bookingId, updateData);
        setBookings(prevBookings => prevBookings.filter(booking => booking.id !== bookingId));
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const handleSampleFilterChange = (filter) => {
        setSampleFilter(filter);
    };

    const filteredData = bookings?.filter((item) => {
        const matchesSearchQuery = item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.phone.includes(searchQuery);
        const matchesSampleFilter = sampleFilter ? item.sampleCollection === sampleFilter : true;
        return matchesSearchQuery && matchesSampleFilter;
    });

    return (
        <Stack style={{ maxHeight: '400px', overflow: 'auto', margin: "0px 80px", width: "100%" }} className={classes.tableContainer} >
            <table className={classes.table}>
                <thead>
                    <tr className={classes.header}>
                        <th className={classes.headerCell}>S.No</th>
                        <th className={classes.headerCell}>Patient Name</th>
                        <th className={classes.headerCell}>Test Name</th>
                        <th className={classes.headerCell}>Phone Number
                            {showSearchInput && (
                                <>
                                    <Image
                                        src={search}
                                        className={classes.search}
                                        alt="search"
                                        onClick={() => setShowSearch(!showSearch)}
                                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                                    />
                                    {showSearch && (
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            className={classes.searchInput}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    )}
                                </>
                            )}
                        </th>
                        <th className={classes.headerCell}>Age</th>
                        <th className={classes.headerCell}>Date</th>
                        <th className={classes.headerCell}>Time</th>
                        <th className={classes.headerCell}>Price</th>
                        <th className={classes.filterCell} >Sample Collection
                            {showDropdown && (<DropdownButton
                                style={{ marginBottom: "10px", margin: "5px" }}
                                id="dropdown-sample-collection"
                                onSelect={handleSampleFilterChange}>
                                <Dropdown.Item eventKey="" >All</Dropdown.Item>
                                <Dropdown.Item eventKey="At Center">At Center</Dropdown.Item>
                                <Dropdown.Item eventKey="Home Collection">Home Collection</Dropdown.Item>
                                
                            </DropdownButton>)}
                        </th>

                        <th className={classes.headerCell}>Payment Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData?.map((item, index) => (
                        <tr key={item.id} className={classes.row}>
                            <td className={classes.cell}>{index + 1}</td>
                            <td className={classes.cell}>{item.name}</td>
                            <td className={classes.cell}>{item.Details ? item.Details.name : 'N/A'}</td>
                            <td className={classes.cell}>{item.phone}</td>
                            <td className={classes.cell}>{item.age}</td>
                            <td className={classes.cell}>{item.date}</td>
                            <td className={classes.cell}>{item.time}</td>
                            <td className={classes.cell}>{item.Details ? item.Details.price : 'N/A'}</td>
                            <td className={classes.cell}>{item.sampleCollection}</td>
                            <td className={classes.cell}>
                                {item.paymentMethod}{' '}
                                {showMoveButton && (
                                    <Button className={classes.add} onClick={() => handleMoveClick(item.id)}>
                                        Move
                                    </Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Stack>
    );
};

export { ActiveBookingGrid };