
import { Theme } from "../../Theme"

export const HeaderStyles = () => {
    return {
        Container: {
            background: Theme.color.transparent,
        },
        heading: {
            ...Theme.typography.textFK2DW800S60,
            color: Theme.color.blue,
            padding: "0px",
            margin: "25px 0"
        },
        admin: {
            ...Theme.typography.textFK2DW800S30,
            color: Theme.color.optional,
            marginBottom: "20px"
        }
    }
}