import React from 'react';
import { Container } from 'react-bootstrap';
import { ActiveBookingStyles } from './ActiveBookings.styles';
import { createUseStyles } from 'react-jss';
import { ActiveBookingGrid } from '../../../Components/ActiveBookingGrid/ActiveBookingGrid';
import { DNA } from 'react-loader-spinner';
import { useInit } from './ActiveBookings.hook';

const useStyles = createUseStyles(ActiveBookingStyles);
const ActiveBookings = () => {
    const classes = useStyles();
    const { state, actions } = useInit();
    React.useEffect(() => {
        actions.getBookingsAction();
    }, []);
    console.log('STATE::', state);


    return (
        <Container fluid className={classes.container}>
            <ActiveBookingGrid
                showMoveButton={true}
                showDropdown={true}
                showSearchInput={false}
                data={state.bookings?.ActiveBookings?.length > 0 ? state.bookings?.ActiveBookings : []}
                updateBookingAction={actions.updateBookingAction}
            />
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <DNA
                    visible={state.isLoading}
                    height="130" width="130"

                    ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
            </div>
        </Container>
    );
};

export default ActiveBookings;
