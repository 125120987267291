import { useReducer, useEffect } from 'react';
import { SCANNING_ACTIONS } from './Scanning.actions';
import { ScanningReducer } from './Scanning.reducer';
import { ScanningInitialState } from './Scanning.models';
import { createTest, deleteTest, getScanningList } from './Scanning.services';


export const useInit = () => {
    const [state, dispatch] = useReducer(ScanningReducer, ScanningInitialState);
    const actions = ScanningActions(dispatch);

    useEffect(() => {
        actions.getScanningAction();
    }, []);

    return { state, actions };
};

const ScanningActions = (dispatch) => {
    const actions = {
        getScanningAction: async () => {
            dispatch({ type: SCANNING_ACTIONS.SET_LOADING, data: true });
            try {
                const response = await getScanningList();
                if (response.statusCode === 200) {
                    dispatch({ type: SCANNING_ACTIONS.SET_SCANNING, data: response.scanning });
                } else {
                    dispatch({ type: SCANNING_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                console.error('Error fetching tests:', error);
                dispatch({ type: SCANNING_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: SCANNING_ACTIONS.SET_LOADING, data: false });
            }
        },

        postAction: async (name, price, type = 'scan') => {
            dispatch({ type: SCANNING_ACTIONS.SET_LOADING, data: true });
            try {
                const response = await createTest(name, price, type);
                if (response.statusCode === 201) {
                    actions.getScanningAction();
                    dispatch({ type: SCANNING_ACTIONS.SET_SCANNING, data: response.scanning });
                } else {
                    dispatch({ type: SCANNING_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                console.error('Error creating test:', error);
                dispatch({ type: SCANNING_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: SCANNING_ACTIONS.SET_LOADING, data: false });
            }
        },

        saveAction: async (name, price, type = 'scan', id) => {
            dispatch({ type: SCANNING_ACTIONS.SET_LOADING, data: true });
            try {
                const response = await createTest(name, price, type, id);
                if (response.statusCode === 200) {
                    actions.getScanningAction();
                    dispatch({ type: SCANNING_ACTIONS.SET_SCANNING, data: response.scanning });
                } else {
                    dispatch({ type: SCANNING_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                console.error('Error creating test:', error);
                dispatch({ type: SCANNING_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: SCANNING_ACTIONS.SET_LOADING, data: false });
            }
        },
        deleteAction: async (id) => {
            dispatch({ type: SCANNING_ACTIONS.SET_LOADING, data: true });
            try {
                const response = await deleteTest(id);
                if (response.statusCode === 200) {
                    actions.getScanningAction();   
                } else {
                    dispatch({ type: SCANNING_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                console.error('Error deleting test:', error);
                dispatch({ type: SCANNING_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: SCANNING_ACTIONS.SET_LOADING, data: false });
            }
        }
    }

    return actions;
};
