import React from "react";
import { Stack } from "react-bootstrap";

import { createUseStyles } from "react-jss";
import { HeaderStyles } from "./Header.styles";

const useStyles = createUseStyles(HeaderStyles);
const Header = () => {
  const classes = useStyles();

  return (
    <Stack className={classes.Container}>
      <h1 className={classes.heading}>Polo Scan and Diagnostics</h1>
      <h5 className={classes.admin}>Admin Portal</h5>
    </Stack>
  );
};

export { Header };
