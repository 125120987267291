import { useEffect, useReducer } from 'react';
import { HEALTH_PACKAGE_ACTIONS } from './HealthPackage.Action';
import { PackagesInitialState } from './HealthPackage.models';
import { PackagesReducer } from './HealthPackage.Reducer';
import { getPackagesList, deletePackage, getAllTestList, createPackage } from './HealthPackage.Services';

export const useInit = () => {
    const [state, dispatch] = useReducer(PackagesReducer, PackagesInitialState);
    const actions = PackagesAction(dispatch);

    useEffect(() => {
        actions.getPackagesList();
        actions.getAllTestList();
    }, []);

    return { state, actions };
};

const PackagesAction = (dispatch) => {
    const actions = {
        getPackagesList: async () => {
            try {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_LOADING, data: true });
                const response = await getPackagesList();
                if (response.statusCode === 200) {
                    dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_HEALTH_PACKAGE, data: response.healthPackage });
                } else {
                    dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                console.error('Error fetching health packages:', error);
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_LOADING, data: false });
            }
        },
        deletePackage: async (id) => {
            try {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_LOADING, data: true });
                const response = await deletePackage(id);
                if (response.statusCode === 200) {
                    actions.getPackagesList(); // Call the action correctly
                } else {
                    dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                console.error('Error deleting package:', error);
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_LOADING, data: false });
            }
        },
        getAllTestList: async () => {
            try {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_LOADING, data: true });
                const response = await getAllTestList()
                if (response.statusCode === 200) {
                    dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ALL_TESTS, data: response.allTest });

                } else {
                    dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_LOADING, data: false });
            }
        },
        setError: (message) => {
            dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: message });
        },
        postPackage: async (name, price, testIds) => {
            dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_LOADING, data: true });
            try {
                const response = await createPackage(name, price, testIds);
                if (response.statusCode === 201) {
                    actions.getPackagesList();
                    dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: null });
                } else {
                    dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: HEALTH_PACKAGE_ACTIONS.SET_LOADING, data: false });
            }
        }
    };

    return actions;
};
