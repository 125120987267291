import { HttpClient } from '../../../../Common/HttpClient/HttpClient';

export const getPackagesList = async () => {
    try {
        const response = await HttpClient.get('/package');
        if (response.status === 200) {
            return {
                statusCode: 200,
                healthPackage: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Somthing want wrong please try again'
            };
        }
    } catch (error) {
        console.log('Error', error);
        return {
            statusCode: 404,
            error: error.message
        };
    }
};

export const deletePackage = async (id) => {
    try {
        const response = await HttpClient.delete(`/package/${id}`);
        if (response.status === 200) {
            return {
                statusCode: 200,
                message: 'Test deleted successfully'
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Something went wrong, please try again'
            };
        }
    } catch (error) {
        return {
            statusCode: error.response ? error.response.status : 404,
            error: error.message
        };
    }
};

export const getAllTestList = async () => {
    try {
        const response = await HttpClient.get('/test');
        if (response.status === 200) {
            return {
                statusCode: 200,
                allTest: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Somthing went wrong please try again'
            };
        }
    } catch (error) {
        console.log('Error', error);
        return {
            statusCode: 404,
            error: error.message
        };
    }
};
export const createPackage = async (name, price, testIds) => {
    try {
        const params = {
            name,
            price,
            testIds
        };
        let response;
        response = await HttpClient.post('/package', params);

        if (response.status === 201 || response.status === 200) {
            return {
                statusCode: response.status,
                tests: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: response.data.message
            };
        }
    } catch (error) {
        console.log("Error ::", error);
        return {
            statusCode: error.response.status || 404,
            error: error.response.data.message.length > 0 ? error.response.data.message : error.message
        };
    }
};
