import { HttpClient } from '../../../../Common/HttpClient/HttpClient';

export const getTestsList = async () => {
    try {
        const response = await HttpClient.get('/test/lab');
        if (response.status === 200) {
            return {
                statusCode: 200,
                tests: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Something went wrong, please try again'
            };
        }
    } catch (error) {
        console.error('Error', error);
        return {
            statusCode: 404,
            error: error.message
        };
    }
};

export const createTest = async (name, price, type, id) => {
    try {
        const params = {
            name,
            price,
            type
        };
        let response;
        if (id) {
            response = await HttpClient.put(`/test/${id}`, params);
        } else {
            response = await HttpClient.post('/test', params);
        }
        if (response.status === 201 || response.status === 200) {
            return {
                statusCode: response.status,
                tests: response.data
            };
        } else {
            return {
                statusCode: response.status,
                error: response.data.message
            };
        }
    } catch (error) {
        console.log("Error ::", error);
        return {
            statusCode: error.response.status || 404,
            error: error.response.data.message.length > 0 ? error.response.data.message : error.message
        };
    }
};

export const deleteTest = async (id) => {
    try {
        const response = await HttpClient.delete(`/test/${id}`);
        if (response.status === 200) {
            return {
                statusCode: 200,
                message: 'Test deleted successfully'
            };
        } else {
            return {
                statusCode: response.status,
                error: 'Something went wrong, please try again'
            };
        }
    } catch (error) {
        return {
            statusCode: error.response ? error.response.status : 404,
            error: error.message
        };
    }
};
