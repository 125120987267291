import { useReducer, useEffect } from 'react';
import { TestsReducer } from './Tests.reducer';
import { TestsInitialState } from './Tests.models';
import { TESTS_ACTIONS } from './Tests.actions';
import { getTestsList, createTest, deleteTest } from './Tests.services';

export const useInit = () => {
    const [state, dispatch] = useReducer(TestsReducer, TestsInitialState);
    const actions = createActions(dispatch);

    useEffect(() => {
        actions.getTestsAction();
    }, []);

    return { state, actions };
};

const createActions = (dispatch) => {
    const actions = {
        getTestsAction: async () => {
            try {
                dispatch({ type: TESTS_ACTIONS.SET_LOADING, data: true });
                const response = await getTestsList();
                if (response.statusCode === 200) {
                    dispatch({ type: TESTS_ACTIONS.SET_TESTS, data: response.tests });
                    dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: null });
                } else {
                    dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: TESTS_ACTIONS.SET_LOADING, data: false });
            }
        },

        postAction: async (name, price, type = 'lab') => {
            dispatch({ type: TESTS_ACTIONS.SET_LOADING, data: true });
            try {
                const response = await createTest(name, price, type);
                if (response.statusCode === 201) {
                    actions.getTestsAction();
                    dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: null });
                } else {
                    dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: TESTS_ACTIONS.SET_LOADING, data: false });
            }
        },


        saveAction: async (name, price, type = 'lab', id) => {
            dispatch({ type: TESTS_ACTIONS.SET_LOADING, data: true });
            try {
                const response = await createTest(name, price, type, id);
                if (response.statusCode === 200) {
                    actions.getTestsAction();
                    dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: null });
                } else {
                    dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: TESTS_ACTIONS.SET_LOADING, data: false });
            }
        },

        deleteAction: async (id) => {
            dispatch({ type: TESTS_ACTIONS.SET_LOADING, data: true });
            try {
                const response = await deleteTest(id);
                if (response.statusCode === 200) {
                    actions.getTestsAction();
                    dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: null });
                } else {
                    dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: response.error });
                }
            } catch (error) {
                dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: error.message });
            } finally {
                dispatch({ type: TESTS_ACTIONS.SET_LOADING, data: false });
            }
        },
        setError: (message) => {
            dispatch({ type: TESTS_ACTIONS.SET_ERROR, data: message });
        }
    };
    return actions


};
