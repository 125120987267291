import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Polo } from './Containers/Login/Polo';
import { Container } from 'react-bootstrap';
import { AppStyles } from './App.styles';
import { createUseStyles } from 'react-jss';
import ActiveBookings from './Containers/MainAdminPortal/ActiveBookings/ActiveBookings';
import { useState, useEffect } from 'react';
import { Header } from './Components/Header/Header';
import IndividualTest from './Containers/MainAdminPortal/ServiceChanges/IndividualTest/IndividualTest';
import Scanning from './Containers/MainAdminPortal/ServiceChanges/ScanningTest/Scanning';
import Menu from './Components/Menu/Menu';
import { AuthContext } from './Containers/AuthContext/AuthContext';
import Packages from './Containers/MainAdminPortal/ServiceChanges/Packages/Packages';
import History from './Containers/MainAdminPortal/History/History';
import { setTokentoHeader } from './Common/HttpClient/HttpClient';
import { ServiceChanges } from './Containers/MainAdminPortal/ServiceChanges/ServiceChanges';

const useStyles = createUseStyles(AppStyles);
function App() {
    const classes = useStyles();
    const [auth, setAuth] = useState(null);
    const login = (user) => {
        setAuth(user);
        setTokentoHeader(user?.token);
        sessionStorage.setItem('auth', JSON.stringify(user));
    };
    const logout = () => {
        sessionStorage.removeItem('auth');
    };
    useEffect(() => {
        const storedAuth = sessionStorage.getItem('auth');
        if (storedAuth) {
            const user = JSON.parse(storedAuth)
            setAuth(user);
            setTokentoHeader(user?.token);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            <Container fluid className={classes.container}>
                <BrowserRouter>
                    {auth && (
                        <>
                            <Header />
                            <Menu />
                        </>
                    )}
                    <Routes>
                        {!auth && <Route path="/" element={<Polo />} />}
                        <Route path="/bookings" element={<ActiveBookings />} />
                        <Route path="/history" element={<History />} />
                        <Route path="/services" element={<ServiceChanges />} />
                    </Routes>
                </BrowserRouter>
            </Container>
        </AuthContext.Provider>
    );
}

export default App;
