import { TESTS_ACTIONS } from './Tests.actions';

export const TestsReducer = (state, action) => {
    switch (action.type) {
        case TESTS_ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.data
            };
        case TESTS_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.data
            };

        case TESTS_ACTIONS.SET_TESTS:
            return {
                ...state,
                tests: action.data
            };
        default:
            return { ...state };
    }
};
