import { BOOKINGS_ACTIONS } from "./ActiveBookings.actions";

export const BookingsReducer = (state, action) => {
    switch (action.type) {
        case BOOKINGS_ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.data
            };
        case BOOKINGS_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.data
            };

        case BOOKINGS_ACTIONS.SET_BOOKINGS:
            return {
                ...state,
                bookings: action.data
            };
        default:
            return { ...state };
    }
}