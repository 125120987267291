import React from 'react';
import { Stack, Button } from 'react-bootstrap';

import { createUseStyles } from 'react-jss';
import { ServicesMenuStyles } from './ServicesMenu.styles';

const useStyles = createUseStyles(ServicesMenuStyles);

const ServicesMenu = ({ onHandleServiceChange, service }) => {
  const classes = useStyles();

  return (
    <Stack direction="horizontal" className={classes.topCard}>
      <Button
        className={classes.button}
        style={{
          backgroundColor: service === "individual" ? "#00B33D" : "",
          color: service === "individual" ? "white" : "",

        }}
        onClick={() => {
          onHandleServiceChange("individual")
        }}
      >
        Individual Test
      </Button>
      <Button
        className={classes.button}
        style={{
          backgroundColor: service === "scanning" ? "#00B33D" : "",
          color: service === "scanning" ? "white" : "",
        }}
        onClick={() => {
          onHandleServiceChange("scanning");
        }}
      >
        Scanning
      </Button>
      <Button
        className={classes.button}
        style={{
          backgroundColor: service === "packages" ? "#00B33D" : "",
          color: service === "packages" ? "white" : "",
        }}
        onClick={() => {
          onHandleServiceChange("packages");
        }}
      >
        Packages
      </Button>
    </Stack>
  );
};

export { ServicesMenu };