import { HISTORY_ACTIONS } from './History.actions';

export const HistoryReducer = (state, action) => {
    switch (action.type) {
        case HISTORY_ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.data
            };
        case HISTORY_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.data
            };

        case HISTORY_ACTIONS.SET_HISTORY:
            return {
                ...state,
                history: action.data
            };
        default:
            return { ...state };
    }
};
