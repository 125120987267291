export const Theme = {
  color: {
    primary: "#1976D3",
    secondary: "#FAC02E",
    optional: "#000000",
    heading: "#293860",
    body: "#7B7B7B",
    icon: "#617A9E",
    border: "#E1E1E1",
    white: "#FFFFFF",
    blue: " #1131A2",
    transparent: "transparent",
    Headingcolor: "#002C58",
    linearGradient: ("90deg, #1976D3 0%, #0D3D6D 100%"),

  },
  borderWhite: {
    border: "2px solid",
    borderColor: "#FFFFFF",
  },
  borderSecondary: {
    border: "2px solid",
    borderColor: "#FAC02E",
  },
  typography: {
    textFexo2W700S70: {
      fontFamily: "'Exo 2'",
      fontSize: "70px",
      fontWeight: "700",
    },
    textFexo2W700S30: {
      fontFamily: "'Exo 2'",
      fontSize: "30px",
      fontWeight: "700",
      lineHeight: "30px",
    },
    textFexo2W600S20: {
      fontFamily: "'Exo 2'",
      fontSize: "20px",
      fontWeight: "600",
    },
    textFHeeboW500S20: {
      fontFamily: "'Heebo'",
      fontSize: "20px",
      fontWeight: "500",
    },
    textFexo2W700S40: {
      fontFamily: "'Exo 2'",
      fontSize: "40px",
      fontWeight: "700",
    },
    textFHeeboW500S15: {
      fontFamily: "'Heebo'",
      fontSize: "15px",
      fontWeight: "500",
    },
    textFHeeboW500S18: {
      fontFamily: "Heebo",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "26.44px",
    },
    textFHeeboW400S25: {
      fontFamily: "Heebo",
      fontSize: "25px",
      fontWeight: "400",
      lineHeight: "36.72px",
    },
    textFHeeboW500S25: {
      fontFamily: "Heebo",
      fontSize: "25px",
      fontWeight: "500",
      lineHeight: "36.72px",
    },
    textFHeeboW400S20: {
      fontFamily: "Heebo",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "29.38px",
    },
    textFHeeboW700S24: {
      fontFamily: "Heebo",
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "35.25px",
    },
    textFK2DW800S60: {
      fontFamily: "K2D",
      fontSize: "60px",
      fontWeight: "800",
      lineHeight: "78px",
      textAlign: "center",

    },
    textFK2DW800S30: {
      fontFamily: "K2D",
      fontSize: "30px",
      fontWeight: "800",
      lineHeight: "39px",
      textAlign: "center",
    },

  },
};
